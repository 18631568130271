import {fetchEntitiesIfNeeded} from 'core/api/actions.entities';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import withSelectedTrackScore from 'core/libs/hoc/withSelectedTrackScore';
import log from 'core/libs/logger';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {compose, lifecycle, withProps} from 'recompose';
import {getUserPosition, getUserScoreOfSelectedTrack} from '../actions';
import TrackScoreLine from './TrackScoreLine';

const TrackScores = ({
  scoresOfSelectedTrack,
  profile,
  userRank,
  userScoreOfSelectedTrack,
  user_id,
  hasCertificate = false,
}) => {
  const isCurrentUserExist =
    scoresOfSelectedTrack &&
    scoresOfSelectedTrack.data &&
    scoresOfSelectedTrack.data.some((user) => user.user_id === user_id);
  return (
    <>
      {scoresOfSelectedTrack &&
      scoresOfSelectedTrack.data &&
      scoresOfSelectedTrack.data.length > 0 ? (
        <div className="ba b--moon-gray br2">
          <div className="bg-white">
            {scoresOfSelectedTrack.data
              .filter(
                (user) =>
                  (user.user_id !== user_id ||
                    (user.user_id === user_id && !hasCertificate)) &&
                  user.profile,
              )
              .map((user, i) => (
                <TrackScoreLine
                  key={i}
                  {...user}
                  topScore={scoresOfSelectedTrack.data[0].total_score}
                  highlight={user.user_id === user_id}
                />
              ))}
            {!isCurrentUserExist &&
              !hasCertificate &&
              !!userRank &&
              userRank > 9 &&
              scoresOfSelectedTrack.data[9] && (
                <TrackScoreLine
                  curr
                  profile={profile}
                  rank={userRank + 1}
                  topScore={scoresOfSelectedTrack.data[9].total_score}
                  total_score={userScoreOfSelectedTrack}
                  highlight
                />
              )}
          </div>
        </div>
      ) : (
        <div className="tc pv6">
          <p className="f5">
            <FormattedMessage id="there_is_no_leaderboard_data_for_this_track" />
          </p>
        </div>
      )}
    </>
  );
};

TrackScores.propTypes = {
  profile: PropTypes.object.isRequired,
  scoresOfSelectedTrack: PropTypes.object.isRequired,
  userRank: PropTypes.number,
  user_id: PropTypes.number,
  userScoreOfSelectedTrack: PropTypes.number,
  hasCertificate: PropTypes.bool,
};

const mapStateToProps = ({
  entities,
  leaderboard: {scoresOfSelectedTrack, userRank, userScoreOfSelectedTrack},
  user,
}) => ({
  scoresOfSelectedTrack,
  user_id: user.profile.data.id,
  user: user.profile.data,
  profile: user.profile.data,
  userRank: userRank.data && userRank.data.rank,
  userScoreOfSelectedTrack:
    userScoreOfSelectedTrack.data && userScoreOfSelectedTrack.data.score,
  certificates: entities.certificates || {status: 'not fetched'},
});

const mapDispatchToProps = (dispatch) => ({
  getRank: (params) => {
    dispatch(getUserPosition(params));
  },
  fetchEntities: (params) => {
    dispatch(fetchEntitiesIfNeeded(params));
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSelectedTrackScore(),
  lifecycle({
    componentDidMount() {
      const {
        user_id,
        getRank,
        selectedTrack: {slug},
        fetchEntities,
        dispatch,
        force = true,
      } = this.props;

      if (user_id) {
        getRank({track_slug: slug, user_id});
        dispatch(
          getUserScoreOfSelectedTrack({track_slug: slug, user_id}, force),
        );
        fetchEntities('certificates');
      }
    },
    componentDidUpdate({
      user_id: prevUser_id,
      selectedTrack: {slug: prevTrackSlug},
    }) {
      const {
        getRank,
        fetchScores,
        selectedTrack: {slug},
        user_id,
        dispatch,
        force = true,
      } = this.props;
      try {
        if (
          (user_id && prevUser_id !== user_id) ||
          (slug && prevTrackSlug !== slug)
        ) {
          getRank({track_slug: slug, user_id});
        }

        if (user_id && slug && prevTrackSlug !== slug)
          dispatch(
            getUserScoreOfSelectedTrack({track_slug: slug, user_id}, force),
          );
      } catch (e) {
        log('Error in Leaderboard, issue 2509', {
          error: e,
          getRank,
          fetchScores,
          slug,
          user_id,
        });
      }
    },
  }),
  spinnerWhileLoading(({scoresOfSelectedTrack}) => {
    return scoresOfSelectedTrack && scoresOfSelectedTrack.status !== 'fetched';
  }),
  withProps(({certificates, user_id, selectedTrack: {id}}) => {
    return {
      hasCertificate:
        certificates &&
        certificates.data &&
        certificates.data.find(
          (certificate) =>
            certificate.id === user_id && certificate.track === id,
        ),
    };
  }),
);
export default enhance(TrackScores);
