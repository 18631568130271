import withCurrentTrack from 'core/libs/hoc/withCurrentTrack';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, withStateHandlers} from 'recompose';
import {tracksTabs} from '../components/styles.scss';
import TrackScores from '../components/TrackScores';
import TrackTab from '../components/TrackTab';

const {PARTNER} = process.env;

const LeaderBoard = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {tracks, selectTrack, selectedTrack} = props;
  return (
    <>
      <Head
        name={formatMessage({id: 'leaderboard_title'})}
        description={formatMessage({id: 'leaderboard_description'})}
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_leaderboard'
              : 'partner_helmet_leaderboard',
        })}
      />
      <div className="w-100 bg-darker-primary-blue tr-ns tc">
        <div className="w-80-ns w-90 center pv5 tr-ns tc white">
          <h4 className="f3 b pb4" data-cy="leaderboard-title">
            <FormattedMessage id="leaderboard_title" />
          </h4>
          <h1 className="f4">
            <FormattedMessage id="leaderboard_description" />
          </h1>
        </div>
      </div>
      <Container>
        <section className="flex-ns db-m db justify-between mt3">
          <section
            className={`self-start w-25-ns w-100-m w-100 mb0-ns mb3 ${tracksTabs}`}
          >
            {tracks.map((track) => (
              <TrackTab
                key={track.id}
                {...track}
                selectedTrack={selectedTrack}
                selectTrack={selectTrack}
              />
            ))}
          </section>
          <section className="w-70-ns w-100-m w-100">
            {selectedTrack && (
              <TrackScores
                selectedTrack={selectedTrack}
                trackSlug={selectedTrack.slug}
              />
            )}
          </section>
        </section>
      </Container>
    </>
  );
};

LeaderBoard.propTypes = {
  tracks: PropTypes.array.isRequired,
  selectTrack: PropTypes.func.isRequired,
  selectedTrack: PropTypes.object.isRequired,
};

const mapStateToProps = ({auth, user}) => ({
  authenticated: auth.authenticated,
  user_id: user.profile.data.id,
});

const enhance = compose(
  connect(mapStateToProps),
  withCurrentTrack,
  withStateHandlers(
    ({tracks, currentTrack}) => {
      return {
        selectedTrack:
          currentTrack.id && !currentTrack.degree ? currentTrack : tracks[0],
      };
    },
    {selectTrack: () => (track) => ({selectedTrack: track})},
  ),
);

export default enhance(LeaderBoard);
