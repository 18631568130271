import React from 'react';
import PropTypes from 'prop-types';
import {Match} from '@reach/router';
import LeaderBoard from './containers/LeaderBoard';

const LeaderBoardRouter = ({tracks}) => {
  return (
    <Match path="/leaderboard/:date_range">
      {({match}) => (
        <LeaderBoard tracks={tracks} date_range={match && match.date_range} />
      )}
    </Match>
  );
};

LeaderBoardRouter.propTypes = {tracks: PropTypes.array.isRequired};

export default LeaderBoardRouter;
