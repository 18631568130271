import decodeId from 'core/libs/helpers/decodeId';
import {graphql} from 'gatsby';
import LeaderboadRouter from 'modules/leaderboard/routes';
import PropTypes from 'prop-types';
import React from 'react';

const LeaderboardPage = ({data}) => {
  const tracks = data.fugoki.tracks.edges
    .filter(({node: track}) => !track.soon)
    .map(({node: track}) => ({
      ...track,
      id: decodeId(track.id),
    }));
  return <LeaderboadRouter tracks={tracks} />;
};

LeaderboardPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LeaderboardPage;

export const pageQuery = graphql`
  query leaderboardQuery($partner: String!) {
    fugoki {
      tracks: allTracks(partner: $partner) {
        edges {
          node {
            id
            name
            slug
            soon
            status
          }
        }
      }
    }
  }
`;
