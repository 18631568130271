import cx from 'classnames';
import Link from 'modules/common/Link';
import PrivateProfileModal from 'modules/common/PrivateProfileModal';
import UserAvatar from 'modules/common/UserAvatar';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {compose, withStateHandlers} from 'recompose';
import barmejFlag from 'theme/assets/images/flags/barmej.svg';
import FlagIcon from './FlagIcon';
import {blueText, margin, marginTop, scoreTab} from './styles.scss';

const ScoreLine = ({
  profile,
  rank,
  curr,
  topScore,
  total_score,
  displayModal,
  toggleModal,
  highlight,
}) => {
  const {
    avatar_url,
    first_name,
    last_name,
    nationality,
    slug,
    is_public,
  } = profile;

  return (
    <div
      className={cx('pt2 pv3 w-100 ph3-ns ph2-m ph1 tr-ns tc cf', scoreTab, {
        'bg-light-green': highlight,
      })}
    >
      <div className="fr w-25-ns w-50-m w-100 mb0-ns mb2-m mb2">
        <div className="flex items-center flex-row-ns flex-column justify-start-ns justify-center">
          <div className="ml3-ns ml0 f5">{rank}</div>
          <Link
            to={is_public ? `/user/${slug}` : undefined}
            onClick={!is_public ? toggleModal : undefined}
          >
            <UserAvatar
              className="br-100 v-mid pointer"
              noBorder
              photo={avatar_url}
            />
          </Link>
          <div className="w-70-ns">
            <Link
              className="black-80 underline-hover pointer"
              to={is_public ? `/user/${slug}` : undefined}
              onClick={!is_public ? toggleModal : undefined}
            >
              <h4 className={`mr3-ns mr0 ${margin}`}>
                {`${first_name} ${last_name}`}
              </h4>
            </Link>
          </div>
        </div>
      </div>
      <div className="fr w-25-ns w-50-m w-100 mb0-ns mb2 tc">
        <div className={marginTop}>
          {nationality ? (
            <FlagIcon name={nationality} />
          ) : (
            <img src={barmejFlag} alt="barmej flag" width="40" height="40" />
          )}
        </div>
      </div>
      <div className="fr w-25-ns w-50-m w-100 mb0-ns mb2 tc">
        <h4 className={`b ${margin}`}>
          {total_score} <FormattedMessage id="points_max" />
        </h4>
      </div>
      <div className="fr w-25-ns w-50-m w-100 tc">
        {topScore > 0 && (
          <h4 className={cx('b', blueText, margin, {f6: curr})}>
            <FormattedMessage
              id={curr ? 'free_certificate_point' : 'points_to_top'}
              values={{points: topScore - total_score}}
            />
          </h4>
        )}
      </div>
      {displayModal && (
        <PrivateProfileModal
          toggleModal={toggleModal}
          first_name={first_name}
          last_name={last_name}
        />
      )}
    </div>
  );
};

ScoreLine.propTypes = {
  profile: PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    nationality: PropTypes.string,
    slug: PropTypes.string,
    is_public: PropTypes.bool,
  }).isRequired,
  rank: PropTypes.number.isRequired,
  topScore: PropTypes.number.isRequired,
  total_score: PropTypes.number.isRequired,
  displayModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  highlight: PropTypes.bool.isRequired,
};

const enhance = compose(
  withStateHandlers(
    {
      displayModal: false,
    },
    {
      toggleModal: ({displayModal}) => () => {
        return {
          displayModal: !displayModal,
        };
      },
    },
  ),
);

export default enhance(ScoreLine);
