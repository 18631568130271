const {PARTNER} = process.env;

export const fetchLeaderboardData = (track_slug, force = false) => {
  return {
    types: [
      'GET_LEADER_BOARD_DATA_REQUEST',
      'GET_LEADER_BOARD_DATA_SUCCESS',
      'GET_LEADER_BOARD_DATA_FAILURE',
    ],
    config: {
      url: `gamify/leaderboard/?track_slug=${track_slug}&partner=${PARTNER}`,
    },
    payload: {
      track_slug,
    },
    shouldCallAPI: ({leaderboard: {scores}}) =>
      !(
        force === false &&
        scores.track_slug === track_slug &&
        (scores.status === 'fetched' || scores.status === 'fetching')
      ),
  };
};

export const fetchLeaderboardDataOfSelectedTrack = (
  track_slug,
  force = false,
) => {
  return {
    types: [
      'GET_LEADER_BOARD_DATA_OF_SELECTED_TRACK_REQUEST',
      'GET_LEADER_BOARD_DATA_OF_SELECTED_TRACK_SUCCESS',
      'GET_LEADER_BOARD_DATA_OF_SELECTED_TRACK_FAILURE',
    ],
    config: {
      url: `gamify/leaderboard/?track_slug=${track_slug}&partner=${PARTNER}`,
    },
    payload: {
      track_slug,
    },
    shouldCallAPI: ({leaderboard: {scoresOfSelectedTrack}}) =>
      !(
        force === false &&
        scoresOfSelectedTrack.track_slug === track_slug &&
        (scoresOfSelectedTrack.status === 'fetched' ||
          scoresOfSelectedTrack.status === 'fetching')
      ),
  };
};

export const getUserPosition = ({track_slug, user_id}) => {
  const url = 'gamify/leaderboard/user_position_on_weekly_leaderboard/';

  return {
    types: [
      'GET_USER_POSITION_REQUEST',
      'GET_USER_POSITION_SUCCESS',
      'GET_USER_POSITION_FAILURE',
    ],
    config: {
      url: `${url}?user_id=${user_id}&track_slug=${track_slug}&partner=${PARTNER}`,
    },
    payload: {track_slug, user_id},
    shouldCallAPI: ({leaderboard: {userRank}}) => {
      return !(
        userRank.track_slug === track_slug &&
        userRank.user_id === user_id &&
        (userRank.status === 'fetched' || userRank.status === 'fetching')
      );
    },
  };
};

export const getUserScore = ({track_slug, user_id}, force = false) => {
  const url = 'gamify/leaderboard/user_score_on_weekly_leaderboard/';

  return {
    types: [
      'GET_USER_SCORE_REQUEST',
      'GET_USER_SCORE_SUCCESS',
      'GET_USER_SCORE_FAILURE',
    ],
    config: {
      url: `${url}?user_id=${user_id}&track_slug=${track_slug}&partner=${PARTNER}`,
    },
    payload: {track_slug, user_id},
    shouldCallAPI: ({leaderboard: {userScore}}) => {
      return !(
        (force === false && userScore.status === 'fetched') ||
        userScore.status === 'fetching'
      );
    },
  };
};

export const getUserScoreOfSelectedTrack = (
  {track_slug, user_id},
  force = false,
) => {
  const url = 'gamify/leaderboard/user_score_on_weekly_leaderboard/';

  return {
    types: [
      'GET_USER_SCORE_OF_SELECTED_TRACK_REQUEST',
      'GET_USER_SCORE_OF_SELECTED_TRACK_SUCCESS',
      'GET_USER_SCORE_OF_SELECTED_TRACK_FAILURE',
    ],
    config: {
      url: `${url}?user_id=${user_id}&track_slug=${track_slug}&partner=${PARTNER}`,
    },
    payload: {track_slug, user_id},
    shouldCallAPI: ({leaderboard: {userScoreOfSelectedTrack}}) => {
      return !(
        (force === false && userScoreOfSelectedTrack.status === 'fetched') ||
        userScoreOfSelectedTrack.status === 'fetching'
      );
    },
  };
};
