import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {selectedTab, Tab} from './styles.scss';

const TrackTab = ({name, id, slug, selectTrack, selectedTrack}) => (
  <div
    className={cx(
      'flex h3 bb b--moon-gray items-center pointer pl2 pr2 lh-copy',
      Tab,
      {[selectedTab]: selectedTrack && selectedTrack.id === id},
    )}
    onKeyPress={() => {
      selectTrack({id, name, slug});
    }}
    onClick={() => {
      selectTrack({id, name, slug});
    }}
  >
    {name}
  </div>
);

TrackTab.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  selectTrack: PropTypes.func.isRequired,
  selectedTrack: PropTypes.object.isRequired,
};

export default TrackTab;
