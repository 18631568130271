import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose, lifecycle, setPropTypes} from 'recompose';
import {fetchLeaderboardDataOfSelectedTrack} from 'modules/leaderboard/actions';

export default ({force = false, callOnUpdate = true} = {}) =>
  compose(
    setPropTypes({
      trackSlug: PropTypes.string,
    }),
    connect(),
    lifecycle({
      componentDidMount() {
        const {trackSlug, dispatch} = this.props;

        if (trackSlug) {
          dispatch(fetchLeaderboardDataOfSelectedTrack(trackSlug, force));
        }
      },
      componentDidUpdate(prevProps) {
        const {dispatch, trackSlug} = this.props;

        if (callOnUpdate && trackSlug && prevProps.trackSlug !== trackSlug) {
          dispatch(fetchLeaderboardDataOfSelectedTrack(trackSlug, force));
        }
      },
    }),
  );
