/* eslint-disable jsx-a11y/click-events-have-key-events */
import Portals from 'modules/common/Portals';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const PrivateProfileModal = ({first_name, last_name, toggleModal}) => {
  return (
    <Portals>
      <div className="flex items-center justify-center fixed h-100 w-100 top-0 left-0 bg-black-90 z-9999">
        <div className="h-100 w-100" onClick={toggleModal} />
        <div className="w5 w-70-m w-50-ns absolute center-absolute br2 pa4 bg-white">
          <div className="tc lh-copy">
            <p className="f4 mv3">
              <FormattedMessage id="profile_of" />{' '}
              <span className="b black-80">
                {first_name} {last_name}
              </span>
              <FormattedMessage id="private" />
            </p>
          </div>
          <div className="mt4 tc">
            <button
              aria-label="cancel"
              onClick={toggleModal}
              className="w-auto-ns w-80-ns w-50 pointer bw0 ph3 pv2 bg-green-light bg-animate hover-bg-green white f6 b br2 mr3-ns mt0-ns mt2"
              type="button"
            >
              <FormattedMessage id="cancel" />
            </button>
          </div>
        </div>
      </div>
    </Portals>
  );
};

PrivateProfileModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string,
};

export default PrivateProfileModal;
