import {graphql} from '@apollo/client/react/hoc';
import CURRENT_TRACK from 'modules/dashboard/graphql/currentTrack.graphql';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  renderNothing,
  setPropTypes,
  withProps,
} from 'recompose';
import decodeId from '../helpers/decodeId';

const {PARTNER} = process.env;

export default compose(
  setPropTypes({
    authenticated: PropTypes.bool.isRequired,
  }),
  branch(
    ({authenticated}) => authenticated,
    graphql(CURRENT_TRACK, {
      fetchPolicy: 'network-only',
      options: () => ({
        variables: {partner: PARTNER},
      }),
      props: ({data}) => {
        if (data.loading) {
          return {
            currentTrack: {
              loading: true,
            },
          };
        }
        if (data.error) {
          return {
            currentTrack: {
              error: true,
              loading: false,
            },
          };
        }
        if (!data.currentTrack || !data.currentTrack.track) {
          return {
            currentTrack: {
              id: null,
              loading: false,
            },
          };
        }
        const {track} = data.currentTrack;
        return {
          currentTrack: {
            ...track,
            id: decodeId(track.id),
            loading: false,
          },
          trackSlug: track.slug,
        };
      },
    }),
    withProps(() => ({currentTrack: {}})),
  ),

  branch(({currentTrack}) => currentTrack.loading, renderNothing),
);
