import React from 'react';
import PropTypes from 'prop-types';
import barmejFlag from 'theme/assets/images/flags/barmej.svg';
import Jordan from 'theme/assets/images/flags/jordan.svg';
import Ireland from 'theme/assets/images/flags/ireland.svg';
import Iceland from 'theme/assets/images/flags/iceland.svg';
import Azerbaijan from 'theme/assets/images/flags/azerbaijan.svg';
import Armenia from 'theme/assets/images/flags/armenia.svg';
import Australia from 'theme/assets/images/flags/australia.svg';
import Afghanistan from 'theme/assets/images/flags/afghanistan.svg';
import Albania from 'theme/assets/images/flags/albania.svg';
import Germany from 'theme/assets/images/flags/germany.svg';
import AntiguaBarbuda from 'theme/assets/images/flags/antigua-and-barbuda.svg';
import Andorra from 'theme/assets/images/flags/andorra.svg';
import Angola from 'theme/assets/images/flags/angola.svg';
import Uruguay from 'theme/assets/images/flags/uruguay.svg';
import Uzbekistan from 'theme/assets/images/flags/uzbekistn.svg';
import Uganda from 'theme/assets/images/flags/uganda.svg';
import Ukraine from 'theme/assets/images/flags/ukraine.svg';
import Ethiopia from 'theme/assets/images/flags/ethiopia.svg';
import Eritrea from 'theme/assets/images/flags/eritrea.svg';
import Spain from 'theme/assets/images/flags/spain.svg';
import Estonia from 'theme/assets/images/flags/estonia.svg';
import Ecuador from 'theme/assets/images/flags/ecuador.svg';
import Indonesia from 'theme/assets/images/flags/indonesia.svg';
import Iran from 'theme/assets/images/flags/iran.svg';
import Italy from 'theme/assets/images/flags/italy.svg';
import Argentina from 'theme/assets/images/flags/argentina.svg';
import UnitedAarabEmirates from 'theme/assets/images/flags/united-arab-emirates.svg';
import RussianFederation from 'theme/assets/images/flags/russia.svg';
import Bahrain from 'theme/assets/images/flags/bahrain.svg';
import Brazil from 'theme/assets/images/flags/brazil.svg';
import Portugal from 'theme/assets/images/flags/portugal.svg';
import BosniaHerzegovina from 'theme/assets/images/flags/bosnia-and-herzegovina.svg';
import Montenegro from 'theme/assets/images/flags/montenegro.svg';
import Algeria from 'theme/assets/images/flags/algeria.svg';
import DominicanRepublic from 'theme/assets/images/flags/dominican-republic.svg';
import Sudan from 'theme/assets/images/flags/sudan.svg';
import Sweden from 'theme/assets/images/flags/sweden.svg';
import ElSalvador from 'theme/assets/images/flags/salvador.svg';
import Senegal from 'theme/assets/images/flags/senegal.svg';
import China from 'theme/assets/images/flags/china.svg';
import Somalia from 'theme/assets/images/flags/somalia.svg';
import Iraq from 'theme/assets/images/flags/iraq.svg';
import Philippines from 'theme/assets/images/flags/philippines.svg';
import Cameroon from 'theme/assets/images/flags/cameroon.svg';
import Kuwait from 'theme/assets/images/flags/kuwait.svg';
import Congo from 'theme/assets/images/flags/republic-of-the-congo.svg';
import Morocco from 'theme/assets/images/flags/morocco.svg';
import Mexico from 'theme/assets/images/flags/mexico.svg';
import SaudiArabia from 'theme/assets/images/flags/saudi-arabia.svg';
import UK from 'theme/assets/images/flags/united-kingdom.svg';
import Norway from 'theme/assets/images/flags/norway.svg';
import Niger from 'theme/assets/images/flags/niger.svg';
import Austria from 'theme/assets/images/flags/austria.svg';
import India from 'theme/assets/images/flags/india.svg';
import USA from 'theme/assets/images/flags/united-states-of-america.svg';
import Japan from 'theme/assets/images/flags/japan.svg';
import Yemen from 'theme/assets/images/flags/yemen.svg';
import Greece from 'theme/assets/images/flags/greece.svg';
import PapuaNewGuinea from 'theme/assets/images/flags/papua-new-guinea.svg';
import Pakistan from 'theme/assets/images/flags/pakistan.svg';
import Palau from 'theme/assets/images/flags/palau.svg';
import Barbados from 'theme/assets/images/flags/barbados.svg';
import BruneiDarussalam from 'theme/assets/images/flags/brunei.svg';
import Belgium from 'theme/assets/images/flags/belgium.svg';
import Bulgaria from 'theme/assets/images/flags/bulgaria.svg';
import Belize from 'theme/assets/images/flags/belize.svg';
import Benin from 'theme/assets/images/flags/benin.svg';
import Panama from 'theme/assets/images/flags/panama.svg';
import Bhutan from 'theme/assets/images/flags/bhutan.svg';
import Botswana from 'theme/assets/images/flags/botswana.svg';
import BurkinaFaso from 'theme/assets/images/flags/burkina-faso.svg';
import Burundi from 'theme/assets/images/flags/burundi.svg';
import Bolivia from 'theme/assets/images/flags/bolivia.svg';
import Poland from 'theme/assets/images/flags/republic-of-poland.svg';
import Peru from 'theme/assets/images/flags/peru.svg';
import Belarus from 'theme/assets/images/flags/belarus.svg';
import Thailand from 'theme/assets/images/flags/thailand.svg';
import TrinidadTobago from 'theme/assets/images/flags/trinidad-and-tobago.svg';
import Turkey from 'theme/assets/images/flags/turkey.svg';
import Turkmenistan from 'theme/assets/images/flags/turkmenistan.svg';
import Chad from 'theme/assets/images/flags/chad.svg';
import CzechRepublic from 'theme/assets/images/flags/czech-republic.svg';
import Togo from 'theme/assets/images/flags/togo.svg';
import Tuvalu from 'theme/assets/images/flags/tuvalu.svg';
import Tokelau from 'theme/assets/images/flags/tokelau.svg';
import Tunisia from 'theme/assets/images/flags/tunisia.svg';
import Tonga from 'theme/assets/images/flags/tonga.svg';
import TimorLeste from 'theme/assets/images/flags/east-timor.svg';
import Jamaica from 'theme/assets/images/flags/jamaica.svg';
import Bahamas from 'theme/assets/images/flags/bahamas.svg';
import Comoros from 'theme/assets/images/flags/comoros.svg';
import SolomonIslands from 'theme/assets/images/flags/solomon-islands.svg';
import VeroIslands from 'theme/assets/images/flags/faroe-islands.svg';
import CookIslands from 'theme/assets/images/flags/cook-islands.svg';
import MarshallIslands from 'theme/assets/images/flags/marshall-island.svg';
import CentralAfricanRepublic from 'theme/assets/images/flags/central-african-republic.svg';
import DemocraticRepublicCongo from 'theme/assets/images/flags/democratic-republic-of-congo.svg';
import UnitedRepublicTanzania from 'theme/assets/images/flags/tanzania.svg';
import RepublicKorea from 'theme/assets/images/flags/south-korea.svg';
import DemocraticPeopleRepublicKorea from 'theme/assets/images/flags/north-korea.svg';
import LaoPeopleDemocraticRepublic from 'theme/assets/images/flags/laos.svg';
import RepublicMoldova from 'theme/assets/images/flags/moldova.svg';
import FormerYugoslavRepublicMacedonia from 'theme/assets/images/flags/republic-of-macedonia.svg';
import Georgia from 'theme/assets/images/flags/georgia.svg';
import Djibouti from 'theme/assets/images/flags/djibouti.svg';
import Dominica from 'theme/assets/images/flags/dominica.svg';
import Rwanda from 'theme/assets/images/flags/rwanda.svg';
import Romania from 'theme/assets/images/flags/romania.svg';
import Zambia from 'theme/assets/images/flags/zambia.svg';
import Zimbabwe from 'theme/assets/images/flags/zimbabwe.svg';
import SaoTomePrincipe from 'theme/assets/images/flags/sao-tome-and-principe.svg';
import SanMarino from 'theme/assets/images/flags/san-marino.svg';
import SaintVincentGrenadines from 'theme/assets/images/flags/st-vincent-and-the-grenadines.svg';
import SaintKittsNevis from 'theme/assets/images/flags/saint-kitts-and-nevis.svg';
import SaintLucia from 'theme/assets/images/flags/st-lucia.svg';
import Samoa from 'theme/assets/images/flags/samoa.svg';
import SriLanka from 'theme/assets/images/flags/sri-lanka.svg';
import Slovakia from 'theme/assets/images/flags/slovakia.svg';
import Slovenia from 'theme/assets/images/flags/slovenia.svg';
import Singapore from 'theme/assets/images/flags/singapore.svg';
import Swaziland from 'theme/assets/images/flags/swaziland.svg';
import Suriname from 'theme/assets/images/flags/suriname.svg';
import Switzerland from 'theme/assets/images/flags/switzerland.svg';
import SierraLeone from 'theme/assets/images/flags/sierra-leone.svg';
import Seychelles from 'theme/assets/images/flags/seychelles.svg';
import Chile from 'theme/assets/images/flags/chile.svg';
import Serbia from 'theme/assets/images/flags/serbia.svg';
import Tajikistan from 'theme/assets/images/flags/tajikistan.svg';
import Amman from 'theme/assets/images/flags/omman.svg';
import Gabon from 'theme/assets/images/flags/gabon.svg';
import Ghana from 'theme/assets/images/flags/ghana.svg';
import Gambia from 'theme/assets/images/flags/gambia.svg';
import Grenada from 'theme/assets/images/flags/grenada.svg';
import Guatemala from 'theme/assets/images/flags/guatemala.svg';
import Guyana from 'theme/assets/images/flags/guyana.svg';
import Guinea from 'theme/assets/images/flags/guinea.svg';
import GuineaBissau from 'theme/assets/images/flags/guinea-bissau.svg';
import EquatorialGuinea from 'theme/assets/images/flags/equatorial-guinea.svg';
import Vanuatu from 'theme/assets/images/flags/vanuatu.svg';
import France from 'theme/assets/images/flags/france.svg';
import Venezuela from 'theme/assets/images/flags/venezuela.svg';
import Finland from 'theme/assets/images/flags/finland.svg';
import Fiji from 'theme/assets/images/flags/fiji.svg';
import VietNam from 'theme/assets/images/flags/vietnam.svg';
import CaboVerdi from 'theme/assets/images/flags/cape-verde.svg';
import Kazakhstan from 'theme/assets/images/flags/kazakhstan.svg';
import Croatia from 'theme/assets/images/flags/croatia.svg';
import Cambodia from 'theme/assets/images/flags/cambodia.svg';
import Canada from 'theme/assets/images/flags/canada.svg';
import Cuba from 'theme/assets/images/flags/cuba.svg';
import CoteDIvoire from 'theme/assets/images/flags/ivory-coast.svg';
import CostaRica from 'theme/assets/images/flags/costa-rica.svg';
import Colombia from 'theme/assets/images/flags/colombia.svg';
import Cyprus from 'theme/assets/images/flags/cyprus.svg';
import Qatar from 'theme/assets/images/flags/qatar.svg';
import Kyrgyzstan from 'theme/assets/images/flags/kyrgyzstan.svg';
import Latvia from 'theme/assets/images/flags/latvia.svg';
import Lebanon from 'theme/assets/images/flags/lebanon.svg';
import Luxembourg from 'theme/assets/images/flags/luxembourg.svg';
import Liberia from 'theme/assets/images/flags/liberia.svg';
import Libya from 'theme/assets/images/flags/libya.svg';
import Lithuania from 'theme/assets/images/flags/lithuania.svg';
import Lesotho from 'theme/assets/images/flags/lesotho.svg';
import Namibia from 'theme/assets/images/flags/namibia.svg';
import Nauru from 'theme/assets/images/flags/nauru.svg';
import Nepal from 'theme/assets/images/flags/nepal.svg';
import Nigeria from 'theme/assets/images/flags/nigeria.svg';
import Nicaragua from 'theme/assets/images/flags/nicaragua.svg';
import NewZealand from 'theme/assets/images/flags/new-zealand.svg';
import Niue from 'theme/assets/images/flags/niue.svg';
import Malta from 'theme/assets/images/flags/malta.svg';
import Mali from 'theme/assets/images/flags/mali.svg';
import Malaysia from 'theme/assets/images/flags/malaysia.svg';
import Madagascar from 'theme/assets/images/flags/madagascar.svg';
import Egypt from 'theme/assets/images/flags/egypt.svg';
import Malawi from 'theme/assets/images/flags/malawi.svg';
import Maldives from 'theme/assets/images/flags/maldives.svg';
import Mongolia from 'theme/assets/images/flags/mongolia.svg';
import Mauritania from 'theme/assets/images/flags/mauritania.svg';
import Mauritius from 'theme/assets/images/flags/mauritius.svg';
import Mozambique from 'theme/assets/images/flags/mozambique.svg';
import Monaco from 'theme/assets/images/flags/monaco.svg';
import Myanmar from 'theme/assets/images/flags/myanmar.svg';
import Micronesia from 'theme/assets/images/flags/micronesia.svg';
import Haiti from 'theme/assets/images/flags/haiti.svg';
import Honduras from 'theme/assets/images/flags/honduras.svg';
import Hungary from 'theme/assets/images/flags/hungary.svg';
import Netherlands from 'theme/assets/images/flags/netherlands.svg';

const elements = {
  آيرلندا: {img: Ireland},
  آيسلندا: {img: Iceland},
  أذربيجان: {img: Azerbaijan},
  أرمينيا: {img: Armenia},
  أستراليا: {img: Australia},
  أفغانستان: {img: Afghanistan},
  ألبانيا: {img: Albania},
  ألمانيا: {img: Germany},
  'أنتيغوا وبربودا': {img: AntiguaBarbuda},
  أندورا: {img: Andorra},
  أنغولا: {img: Angola},
  أوروغواي: {img: Uruguay},
  أوزبكستان: {img: Uzbekistan},
  أوغندا: {img: Uganda},
  أوكرانيا: {img: Ukraine},
  إثيوبيا: {img: Ethiopia},
  إريتريا: {img: Eritrea},
  إسبانيا: {img: Spain},
  إستونيا: {img: Estonia},
  إكوادور: {img: Ecuador},
  إندونيسيا: {img: Indonesia},
  إيران: {img: Iran},
  إيطاليا: {img: Italy},
  الأرجنتين: {img: Argentina},
  الأردن: {img: Jordan},
  'الإمارات العربيّة المتحدة': {img: UnitedAarabEmirates},
  'الاتحاد الروسي': {img: RussianFederation},
  البحرين: {img: Bahrain},
  البرازيل: {img: Brazil},
  البرتغال: {img: Portugal},
  'البوسنة والهرسك': {img: BosniaHerzegovina},
  'الجبل الأسود': {img: Montenegro},
  الجزائر: {img: Algeria},
  'الجمهوريّة الدومينيكيّة': {img: DominicanRepublic},
  السودان: {img: Sudan},
  السويد: {img: Sweden},
  السلفادور: {img: ElSalvador},
  السنغال: {img: Senegal},
  الصين: {img: China},
  الصومال: {img: Somalia},
  العراق: {img: Iraq},
  الفلبين: {img: Philippines},
  الكاميرون: {img: Cameroon},
  الكويت: {img: Kuwait},
  الكونغو: {img: Congo},
  المغرب: {img: Morocco},
  المكسيك: {img: Mexico},
  'المملكة العربيّة السعوديّة': {img: SaudiArabia},
  'المملكة المتحدة': {img: UK},
  النرويج: {img: Norway},
  النيجر: {img: Niger},
  النمسا: {img: Austria},
  الهند: {img: India},
  'الولايات المتحدة الأمريكيّة': {img: USA},
  اليابان: {img: Japan},
  اليمن: {img: Yemen},
  اليونان: {img: Greece},
  'بابوا غينيا الجديدة': {img: PapuaNewGuinea},
  باكستان: {img: Pakistan},
  بالاو: {img: Palau},
  بربادوس: {img: Barbados},
  'بروني دار السلام': {img: BruneiDarussalam},
  بلجيكا: {img: Belgium},
  بلغاريا: {img: Bulgaria},
  بليز: {img: Belize},
  بنن: {img: Benin},
  بنما: {img: Panama},
  بوتان: {img: Bhutan},
  بوتسوانا: {img: Botswana},
  'بوركينا فاسو': {img: BurkinaFaso},
  بوروندي: {img: Burundi},
  بوليفيا: {img: Bolivia},
  بولندا: {img: Poland},
  بيرو: {img: Peru},
  بيلاروس: {img: Belarus},
  تايلند: {img: Thailand},
  'ترينيداد وتوباغو': {img: TrinidadTobago},
  تركيا: {img: Turkey},
  تركمانستان: {img: Turkmenistan},
  تشاد: {img: Chad},
  تشيكيا: {img: CzechRepublic},
  توغو: {img: Togo},
  توفالو: {img: Tuvalu},
  توكيلاو: {img: Tokelau},
  تونس: {img: Tunisia},
  'Tunisie-Bizerte-Jarzouna': {img: Tunisia},
  تونغا: {img: Tonga},
  'تيمور - ليشتي': {img: TimorLeste},
  جامايكا: {img: Jamaica},
  'جزر البهاما': {img: Bahamas},
  'جزر القمر': {img: Comoros},
  'جزر سليمان': {img: SolomonIslands},
  'جزر فيرويه': {img: VeroIslands},
  'جزر كوك': {img: CookIslands},
  'جزر مارشال': {img: MarshallIslands},
  'جمهوريّة أفريقيا الوسطى': {img: CentralAfricanRepublic},
  'جمهوريّة الكونغو الديمقراطية': {img: DemocraticRepublicCongo},
  'جمهوريّة تنزانيا المتحدة': {img: UnitedRepublicTanzania},
  'جمهوريّة كوريا': {img: RepublicKorea},
  'جمهوريّة كوريا الشعبيّة الديمقراطية': {img: DemocraticPeopleRepublicKorea},
  'جمهوريّة لاو الديمقراطية الشعبيّة': {img: LaoPeopleDemocraticRepublic},
  'جمهوريّة مولدوفا': {img: RepublicMoldova},
  'جمهوريّة مقدونيا اليوغوسلافية السابقة': {
    img: FormerYugoslavRepublicMacedonia,
  },
  جورجيا: {img: Georgia},
  جيبوتي: {img: Djibouti},
  دومينيكا: {img: Dominica},
  رواندا: {img: Rwanda},
  رومانيا: {img: Romania},
  زامبيا: {img: Zambia},
  زمبابوي: {img: Zimbabwe},
  'سان تومي وبرنسيبي': {img: SaoTomePrincipe},
  'سان مارينو': {img: SanMarino},
  'سانت فنسنت وجزر غرينادين': {img: SaintVincentGrenadines},
  'سانت كيتس ونيفيس': {img: SaintKittsNevis},
  'سانت لوسيا': {img: SaintLucia},
  ساموا: {img: Samoa},
  'سري لانكا': {img: SriLanka},
  سلوفاكيا: {img: Slovakia},
  سلوفينيا: {img: Slovenia},
  سنغافورة: {img: Singapore},
  سوازيلند: {img: Swaziland},
  سورينام: {img: Suriname},
  سويسرا: {img: Switzerland},
  سيراليون: {img: SierraLeone},
  سيشيل: {img: Seychelles},
  شيلي: {img: Chile},
  صربيا: {img: Serbia},
  طاجيكستان: {img: Tajikistan},
  عمان: {img: Amman},
  غابون: {img: Gabon},
  غانا: {img: Ghana},
  غامبيا: {img: Gambia},
  غرينادا: {img: Grenada},
  غواتيمالا: {img: Guatemala},
  غيانا: {img: Guyana},
  غينيا: {img: Guinea},
  'غينيا - بيساو': {img: GuineaBissau},
  'غينيا الاستوائيّة': {img: EquatorialGuinea},
  فانواتو: {img: Vanuatu},
  فرنسا: {img: France},
  فنزويلا: {img: Venezuela},
  فنلندا: {img: Finland},
  فيجي: {img: Fiji},
  'فييت نام': {img: VietNam},
  'كابو فيردي': {img: CaboVerdi},
  كازاخستان: {img: Kazakhstan},
  كرواتيا: {img: Croatia},
  كمبوديا: {img: Cambodia},
  كندا: {img: Canada},
  كوبا: {img: Cuba},
  'كوت ديفوار': {img: CoteDIvoire},
  كوستاريكا: {img: CostaRica},
  كولومبيا: {img: Colombia},
  قبرص: {img: Cyprus},
  قطر: {img: Qatar},
  قيرغيزستان: {img: Kyrgyzstan},
  لاتفيا: {img: Latvia},
  لبنان: {img: Lebanon},
  لكسمبرغ: {img: Luxembourg},
  ليبريا: {img: Liberia},
  ليبيا: {img: Libya},
  ليتوانيا: {img: Lithuania},
  ليسوتو: {img: Lesotho},
  ناميبيا: {img: Namibia},
  ناورو: {img: Nauru},
  نيبال: {img: Nepal},
  نيجيريا: {img: Nigeria},
  نيكاراغوا: {img: Nicaragua},
  نيوزيلندا: {img: NewZealand},
  نيوي: {img: Niue},
  مالطة: {img: Malta},
  مالي: {img: Mali},
  ماليزيا: {img: Malaysia},
  مدغشقر: {img: Madagascar},
  مصر: {img: Egypt},
  ملاوي: {img: Malawi},
  ملديف: {img: Maldives},
  منغوليا: {img: Mongolia},
  موريتانيا: {img: Mauritania},
  موريشيوس: {img: Mauritius},
  موزامبيق: {img: Mozambique},
  موناكو: {img: Monaco},
  ميانمار: {img: Myanmar},
  ميكرونيزيا: {img: Micronesia},
  هايتي: {img: Haiti},
  هندوراس: {img: Honduras},
  هنغاريا: {img: Hungary},
  هولندا: {img: Netherlands},
};

const FlagIcon = ({name}) => {
  const element = name && elements[name];
  if (element) return <img alt={name} src={element.img} />;
  return <img src={barmejFlag} alt="barmej flag" width="40" height="40" />;
};

FlagIcon.propTypes = {
  name: PropTypes.string,
};

export default FlagIcon;
